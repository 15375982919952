import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const AffiliateLink = ({ refresh }) => {
  const [copySuccess, setCopySuccess] = useState("");

  const linkafiliate = `https://wornnex.vip/register/${localStorage.getItem(
    "@wornnex:affiliateLink"
  )}`;

  let codigoinv = localStorage.getItem("@wornnex:affiliateLink");

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkafiliate)
      .then(() => {
        toast.success("Link copiado com sucesso!");
      })
      .catch(() => {
        toast.error("Falha ao copiar o link.");
      });
  };

  return (
    <>
      <div className="flex w-full justify-center   items-center rounded-md p-4 relative z-10">
        {/* Botão de Copiar e Link */}
        <div className="mr-10">
          <div className="flex">
            <button
              onClick={copyToClipboard}
              className="bg-[#2e3543] text-white rounded-l-md py-2 px-4 transition duration-200"
            >
              Copiar
            </button>
            <input
              type="text"
              value={codigoinv}
              readOnly
              className="rounded-r-md p-2 w-full text-black"
            />
          </div>

          {copySuccess && <p className="mt-2 text-green-600">{copySuccess}</p>}
        </div>
      </div>
    </>
  );
};

export default AffiliateLink;
