import { api } from "../../services/api";

export async function fetchAfiliadoQtd() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@wornnex:token");

    api
      .get("/getafili", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
