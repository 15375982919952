import React from "react";
import "./ScrollingText.css"; // Importa o CSS

const InvestmentBenefits = () => {
  const benefits = [
    "Descubra o futuro dos investimentos e conquiste sua liberdade financeira com inteligência artificial!",
    "Explore o futuro dos investimentos e conquiste sua liberdade financeira, com novidades toda semana!",
  ];

  return (
    <div className="scrolling-container">
      <div className="scrolling-text">
        {benefits.map((benefit, index) => (
          <span key={index} className="text-item">
            {benefit}
          </span>
        ))}
      </div>
    </div>
  );
};

export default InvestmentBenefits;
