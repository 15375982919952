import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: white; /* Cor do loader para branco */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem; /* Espaçamento inferior */

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Title = styled.h1`
  color: white; /* Cor da frase "WORNNEX" para branco */
  font-size: 2rem; /* Tamanho da fonte */
  font-weight: 800; /* Extra bold */
  margin-bottom: 1rem; /* Espaçamento inferior */
`;

const LoadingSpinner = () => (
  <div className="flex flex-col items-center">
    <Title>WORNNEX</Title> {/* Frase destacada em branco */}
    <Loader />
    <p style={{ color: "white" }}>Carregando... Por favor, aguarde.</p>{" "}
    <p style={{ color: "white" }}>wornnex.app</p>
    {/* Texto adicional em branco */}
  </div>
);

export default LoadingSpinner;
