import React, { useState, useEffect } from "react";
import { BiLockAlt } from "react-icons/bi";
import logo from "./../../../assets/logo/logo.png";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";
import { fetchCadastrar } from "../../../integration/cadastrar/fetchCadastrar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const nomedosistema = "WORNNEX";

  const { "*": registrationId } = useParams(); // Use * para pegar tudo após /register/

  const [senha, setSenha] = useState("");
  const [repetirsenha, setRepetirsenha] = useState("");
  const [celular, setCelular] = useState("");
  const [codigo, setCodigo] = useState("");

  useEffect(() => {
    // Se não houver convite, redireciona para /register com o convite
    if (registrationId) {
      setCodigo(registrationId); // Se o convite existir, armazena o código
    }
  }, [registrationId]);

  const handleSenha = (e) => setSenha(e.target.value);
  const handleSenha2 = (e) => setRepetirsenha(e.target.value);
  const handleCel = (e) => setCelular(e.target.value);
  const handleCodigo = (e) => setCodigo(e.target.value);

  async function funcAlterarSenha() {
    if (senha !== repetirsenha) {
      toast.error("As senhas não correspondem");
      return; // Impede que a função continue se as senhas não coincidirem
    }

    try {
      const resp = await fetchCadastrar(celular, senha, repetirsenha, codigo);
      toast.success(resp);
      // Recarrega a página após 3 segundos
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (erro) {
      toast.error(erro);
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center h-screen bg-gradient-to-tr from-[#ffffff] to-[#DCDCDC] bg-cover bg-center relative">
        <div className="justify-center items-center self-center z-10 p-5 rounded-md">
          <div className="w-full max-w-md">
            <h1 className="flex items-center justify-center mb-8">
              <img className="h-24" src={logo} alt="Logo" />
            </h1>

            <div className="flex items-center bg-[#d7d7d7] border-2 border-[#c7c7c7] text-[#516f7b] py-2 px-3 rounded-2xl mb-4">
              <MdOutlinePhoneAndroid className="h-5 w-5 text-[#516f7b]" />
              +55
              <input
                type="text"
                className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
                value={celular}
                onChange={handleCel}
                placeholder="Insira o número da conta"
              />
            </div>

            <div className="flex items-center bg-[#d7d7d7] border-2 border-[#c7c7c7] py-2 px-3 rounded-2xl mb-4">
              <BiLockAlt className="h-5 w-5 text-[#2d4853]" />
              <input
                type="text" // Alterado para password
                className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
                value={senha}
                onChange={handleSenha}
                placeholder="Senha"
              />
            </div>

            <div className="flex items-center bg-[#d7d7d7] border-2 border-[#c7c7c7] py-2 px-3 rounded-2xl mb-4">
              <BiLockAlt className="h-5 w-5 text-[#2d4853]" />
              <input
                type="text" // Alterado para password
                className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
                value={repetirsenha}
                onChange={handleSenha2}
                placeholder="Confirmar Senha"
              />
            </div>

            <div className="flex items-center bg-[#d7d7d7] border-2 border-[#c7c7c7] text-[#516f7b] py-2 px-3 rounded-2xl mb-4">
              <FaUserPlus className="h-5 w-5 text-[#516f7b]" />
              <input
                type="text"
                className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
                value={codigo}
                onChange={handleCodigo}
                placeholder="Código de Convite"
              />
            </div>

            <button
              onClick={funcAlterarSenha} // Mantém o onClick aqui
              className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#2d4853] to-[#375d69] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
            >
              Registrar
            </button>

            <button
              onClick={() => navigate("/")}
              className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#d7d7d7] to-[#d7d7d7] mt-4 py-2 rounded-2xl text-[#2d4853] mb-2 flex items-center justify-center"
            >
              Voltar
            </button>

            <h1 className="text-[#656565] text-center text-[10px] mt-4">
              Copyright © 2022 - 2024 {nomedosistema}
            </h1>
            <h1 className="text-[#656565] text-center text-[10px]">
              Todos os direitos reservados
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
