import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Cartao from "../cartao/index";
import bg01 from "../../assets/robos/bg1.png";

const AffiliateLink = ({ refresh }) => {
  const [copySuccess, setCopySuccess] = useState("");

  const linkafiliate = `https://wornnex.vip/register/${localStorage.getItem(
    "@wornnex:affiliateLink"
  )}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkafiliate)
      .then(() => {
        toast.success("Link copiado com sucesso!");
      })
      .catch(() => {
        toast.error("Falha ao copiar o link.");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center bg-cover bg-center p-4 relative">
        {/* Conteúdo centralizado acima da camada de opacidade */}
        <div className="   mt-20 items-center rounded-md p-4 relative ">
          {/* Botão de Copiar e Link */}
          <div className="mr-10 mb-10 w-full">
            <h2 className="text-[15px] font-semibold mb-2 text-slate-800">
              Link de Convite
            </h2>
            <div className="flex">
              <button
                onClick={copyToClipboard}
                className="bg-[#2e3543] text-white rounded-l-md py-2 px-4 transition duration-200"
              >
                Copiar
              </button>
              <input
                type="text"
                value={linkafiliate}
                readOnly
                className="rounded-r-md p-2 w-full text-black"
              />
            </div>
            {copySuccess && (
              <p className="mt-2 text-green-600">{copySuccess}</p>
            )}
          </div>

          {/* Cartão ao lado direito */}
          <Cartao refresh={refresh} />
        </div>
      </div>
    </>
  );
};

export default AffiliateLink;
