import React from "react";
import "flowbite";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContextProvider } from "./contexts/AuthContext";

//Erro
import Error404 from "./pages/Private/error/404";
import DisplayPage from "./pages/Private/error/DisplayPage";

//Protect Router
import RedirectRouteLogin from "./routes/RedirectRouteLogin";
import ProtectedRoute from "./routes/ProtectedRoute";
import Login from "./pages/Public/login/Login";
import Cadastrar from "./pages/Public/cadastrar/Index";

//private
import Dashboard from "./pages/Private/dashboard/Index";
import Deposito from "./pages/Private/deposito/Index";
import Atividade from "./pages/Private/atividade/Index";
import Perfil from "./pages/Private/perfil/Index";
import Equipe from "./pages/Private/equipe/Index";

function App() {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>WORNNEX</title>
        </Helmet>
      </div>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            <Route element={<RedirectRouteLogin />}>
              <Route path="*" element={<Login />} />
              <Route index element={<Login />} />

              <Route path="/register/*" element={<Cadastrar />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/deposit" element={<Deposito />} />
              <Route path="/activity" element={<Atividade />} />
              <Route path="/perfil" element={<Perfil />} />
              <Route path="/group" element={<Equipe />} />
            </Route>
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
