import Menu from "../../../components/menu";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import Footer from "../../../components/footer/index";

import NoticiaCard from "../../../components/noticia/index";
import GroupCard from "../../../components/group/index";

import LoadingSpinner from "./LoadingSpinner";

const Index = () => {
  const [loading, setLoading] = useState(false); // Estado de loading

  const MobileView = () => {
    const columnHeight = {
      col1: "100%",
    };

    return (
      <>
        <ToastContainer />
        <div className="flex flex-col h-screen">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <LoadingSpinner /> {/* Componente de loading */}
            </div>
          ) : (
            <div className="flex flex-grow">
              {/* Coluna do conteúdo */}
              <div
                className="w-full md:w-auto bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4] border-l-2 border-gray-200"
                style={{ height: columnHeight.col1 }}
              >
                <NoticiaCard />
                <Menu />

                <div className="flex items-center justify-center ">
                  <div className="grid grid-cols-1 p-5 gap-10 w-full ">
                    <Footer />
                    <GroupCard />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const DesktopView = () => {
    return (
      <>
        <ToastContainer />
        <div className="flex flex-col h-screen">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <LoadingSpinner /> {/* Componente de loading */}
            </div>
          ) : (
            <div className="flex flex-grow">
              {/* Coluna do conteúdo */}
              <div className="w-full  bg-gradient-to-tr from-white to-gray-300 border-l-2 border-gray-200">
                <NoticiaCard />
                <Menu />

                <div className="flex items-center justify-center ">
                  <div className="grid grid-cols-2 p-5 gap-10 w-full ">
                    <Footer />
                    <GroupCard />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <ToastContainer />
      <div className="block md:hidden">
        <MobileView />
      </div>
      <div className="hidden md:block">
        <DesktopView />
      </div>
    </div>
  );
};

export default Index;
