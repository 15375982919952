import React, { useState, useEffect } from "react";
import { formatToBRL } from "brazilian-values";
import { fetchSaldo } from "../../integration/saldo/fetchSaldo";
import { fetchPayment } from "../../integration/gerarpagamento/fetchPayment";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

const Deposito = () => {
  const navigate = useNavigate();

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [copiaecola, setCopiacola] = useState("");
  const [qrCodeData, setQrCodeData] = useState("");
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false); // Estado de loading

  // Valores em centavos
  const amounts = [
    10000, 25000, 60000, 140000, 320000, 550000, 1200000, 2000000,
  ];

  // Função para lidar com o pagamento
  const handlePayment = async () => {
    setLoading(true);
    if (selectedAmount) {
      try {
        const resp = await fetchPayment(selectedAmount);
        setLoading(false);
        setQrCodeData(resp.qrcode);
        setCopiacola(resp.copiaEcola);
      } catch (error) {
        setLoading(false);
        alert("Erro ao gerar pagamento. Tente novamente.");
      }
    } else {
      setLoading(false);
      alert("Por favor, selecione um valor.");
    }
  };

  // UseEffect para buscar saldo ao montar o componente
  useEffect(() => {
    functionBalance();
  }, []);

  // Função para buscar o saldo
  async function functionBalance() {
    try {
      const resp = await fetchSaldo();
      setBalance(resp.balance);
    } catch (error) {
      setBalance(0);
    }
  }

  // Função para copiar o QR Code para a área de transferência
  const handleCopy = () => {
    navigator.clipboard
      .writeText(copiaecola)
      .then(() => alert("Copia e cola copiado com sucesso!"))
      .catch(() => alert("Erro ao copiar. Tente novamente."));
  };

  // Renderização do componente
  return (
    <div className="flex flex-col items-center w-full  rounded-lg  mb-10  max-w-lg m-auto text-white">
      {/* Botões de Seleção de Valor */}

      {loading && (
        <div className="flex items-center mt-10 justify-center h-full">
          <LoadingSpinner />
        </div>
      )}

      {!qrCodeData && !loading && (
        <>
          <h2 className="text-[25px] text-[#2e3543] uppercase font-extrabold mt-5 mb-6 text-center">
            Depósito via PIX
          </h2>
          <div className="mb-6 p-4 bg-[#2e3543] rounded-md w-full text-center">
            <p className="text-sm text-slate-300">Saldo Atual</p>
            <p className="text-lg font-semibold text-slate-300">
              {formatToBRL(balance)}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6 w-full">
            {amounts.map((amount) => (
              <button
                key={amount}
                onClick={() => setSelectedAmount(amount)}
                className={`py-4 rounded-md text-lg font-semibold border border-slate-400 ${
                  selectedAmount === amount
                    ? "bg-green-500 text-white"
                    : "bg-[#2e3543] text-slate-200"
                } transition duration-200 transform hover:scale-105`}
              >
                R$ {(amount / 100).toLocaleString("pt-BR")}{" "}
                {/* Conversão para formato de moeda */}
              </button>
            ))}
          </div>
          <button
            onClick={handlePayment}
            className="bg-[#486e7d] w-full text-white font-bold py-3 px-6 rounded-md transition duration-200"
          >
            Gerar QR Code
          </button>
        </>
      )}

      {qrCodeData && !loading && (
        <div className="mt-8 p-4 bg-[#2e3543] rounded-md text-center w-full">
          <h3 className="text-lg font-semibold mb-2">QR Code para Pagamento</h3>
          <p className="mb-4 text-slate-400">
            Valor: R$ {(selectedAmount / 100).toLocaleString("pt-BR")}{" "}
            {/* Conversão para formato de moeda */}
          </p>
          <p className="text-sm text-slate-300">Método de Pagamento: PIX</p>
          <img src={qrCodeData} alt="QR Code" className="mt-4 mb-4 mx-auto" />
          <div className="flex flex-col space-y-4 mt-4">
            <button
              onClick={handleCopy}
              className="bg-[#486e7d] text-white font-bold py-2 px-4 rounded-md transition duration-200"
            >
              Copiar Código
            </button>

            <button
              onClick={() => navigate("/")}
              className="bg-[#486e7d] text-white font-bold py-2 px-4 rounded-md transition duration-200"
            >
              Já Paguei
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Deposito;
