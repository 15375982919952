import { api } from "../../services/api";

export async function fetchCadastrar(
  phone,
  password,
  confirm_password,
  codigo
) {
  return new Promise((resolve, reject) => {
    api
      .post(
        "/register",
        {
          phone,
          password,
          confirm_password,
          code_inv: codigo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
