import React, { useState, useEffect } from "react";
import { fetchAtividades } from "../../integration/atividades/fetchAtividades";
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-toastify";
import { formatToBRL } from "brazilian-values";

const RendimentosTable = () => {
  const [rendimentos, setRendimentos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    functionAtividades();
  }, []);

  const functionAtividades = async () => {
    setLoading(true);
    try {
      const resp = await fetchAtividades();

      setRendimentos(resp);
    } catch (erro) {
      setRendimentos([]);
      toast.error(erro);
    } finally {
      setLoading(false);
    }
  };

  const handleSacarRendimento = (id) => {
    toast.info(`Saque solicitado para o rendimento ID: ${id}`);
  };

  return (
    <div className="overflow-x-auto w-full">
      {loading && !rendimentos && (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      )}

      {!loading && rendimentos.length === 0 && (
        <div className="text-[18px] text-slate-700 flex items-center justify-center h-full">
          <p>Sem Investimentos</p>
        </div>
      )}

      {rendimentos.length > 0 && !loading && (
        <table className="min-w-full bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <thead className="bg-gray-700 text-gray-200 uppercase text-sm">
            <tr>
              <th className="py-3 px-6">ID</th>
              <th className="py-3 px-6">Produto</th>
              <th className="py-3 px-6">Status</th>
              <th className="py-3 px-6">Valor Investido</th>
              <th className="py-3 px-6">Rendimento/Dia</th>
              <th className="py-3 px-6 bg-green-400">Rendimento Atual</th>
              <th className="py-3 px-6">Dias</th>
              <th className="py-3 px-6">Data de Compra</th>
              <th className="py-3 px-6">Ações</th>
            </tr>
          </thead>
          <tbody className="text-gray-300 text-sm">
            {rendimentos.map((rendimento) => (
              <tr
                key={rendimento.id}
                className="hover:bg-gray-700 transition-colors duration-200 text-center"
              >
                <td className="py-3 px-6">{rendimento.id}</td>
                <td className="py-3 px-6">{rendimento.product}</td>
                <td
                  className={`py-3 px-6 font-semibold ${
                    rendimento.status === "ativo" ||
                    rendimento.status === "Ativo"
                      ? "text-green-400"
                      : "text-red-500"
                  }`}
                >
                  {rendimento.status}
                </td>
                <td className="py-3 px-6">
                  {formatToBRL(rendimento.investedAmount) || "Não disponível"}
                </td>
                <td className="py-3 px-6">
                  {formatToBRL(rendimento.dailyReturn) || "Não disponível"}
                </td>
                <td className="py-3 px-6 text-green-400 ">
                  {formatToBRL(rendimento.currentReturn) || "Não disponível"}
                </td>
                <td className="py-3 px-6">{rendimento.days}</td>
                <td className="py-3 px-6">{rendimento.purchaseDate}</td>

                <td className="py-3 px-6">
                  <button
                    ///   onClick={() => handleSacarRendimento(rendimento.id)}
                    className="px-4 py-2 bg-[#486e7d] text-white font-semibold rounded-md   "
                  >
                    ...
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RendimentosTable;
