import Menu from "../../../components/menu";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { formatToBRL } from "brazilian-values";

import { fetchProdutos } from "../../../integration/produtos/fetchProdutos";

import InvestmentCard from "../../../components/card_investiment/index";
import InvestmentCard_mobile from "../../../components/card_investiment/index_mobile";

import AffiliateLink from "../../../components/card_invity/index";
import AffiliateLink_mobile from "../../../components/card_invity/index_mobile";

import NoticiaCard from "../../../components/noticia/index";

import LoadingSpinner from "./LoadingSpinner";

const Index = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de loading
  const [onrefresh, setOnrefresh] = useState(false); // Estado de refresh

  useEffect(() => {
    functionProdutos();
  }, []);

  const functionProdutos = async () => {
    try {
      const resp = await fetchProdutos();
      setProdutos(resp);
      toast.success("List of updated robots!");
    } catch (erro) {
      setProdutos([]);
      toast.error(erro);
    } finally {
      setLoading(false); // Para parar o loading após a requisição
    }
  };

  const MobileView = () => {
    const columnHeight = {
      col1: "100%",
    };

    // Função de notificação para sucesso
    const notifySuccess = async (produto) => {
      toast.success(`${produto}`);
      window.location.reload(); // Recarregar a página após 3 segundos
    };

    // Função de notificação para erro
    const notifyError = (produto) => {
      toast.error(`${produto}`);
    };

    return (
      <div className="flex flex-col h-screen bg-[#262626] md:flex-row">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <LoadingSpinner /> {/* Componente de loading */}
          </div>
        ) : (
          <>
            <nav className={`z-10 text-black w-full  fixed  `}>
              <Menu />
            </nav>
            <div
              className="w-full md:w-auto   border-l-2 border-gray-200 ml-0 md:ml-64"
              style={{ height: columnHeight.col1 }}
            >
              <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 p-5 gap-10 w-full bg-gradient-to-tr from-white to-gray-300">
                  <AffiliateLink_mobile refresh={onrefresh} />

                  <div className="flex flex-col items-center justify-center ">
                    {produtos.map((produto) => (
                      <InvestmentCard_mobile
                        key={produto.id}
                        status={produto.status}
                        name={produto.type}
                        idproduto={produto.id}
                        price={`${
                          formatToBRL(produto.price) || "Não disponível"
                        }`} // Formata o preço
                        dailyProfit={`${
                          formatToBRL(produto.dailyProfit) || "Não disponível"
                        }`} // Formata o lucro diário
                        totalProfit={`${
                          formatToBRL(produto.totalProfit) || "Não disponível"
                        }`} // Formata o lucro total
                        days={parseInt(produto.returnPeriod)} // Extraí os dias do período de retorno
                        onInvest={(status) => {
                          if (status === "Investimento feito com sucesso!") {
                            notifySuccess(status); // Chama notifySuccess se o investimento for bem-sucedido
                          } else {
                            notifyError(status); // Chama notifyError se houver um erro
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const DesktopView = () => {
    // Função de notificação para sucesso
    const notifySuccess = (produto) => {
      toast.success(`${produto}`);
      setOnrefresh(true);
    };

    // Função de notificação para erro
    const notifyError = (produto) => {
      toast.error(`${produto}`);
      setOnrefresh(true);
    };

    return (
      <div className="flex flex-col h-screen">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <LoadingSpinner /> {/* Componente de loading */}
          </div>
        ) : (
          <div className="flex flex-grow">
            {/* Coluna do conteúdo */}
            <div className="w-full  bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]    ">
              <NoticiaCard />
              <Menu />

              <div className="grid grid-cols-1 w-full">
                <AffiliateLink refresh={onrefresh} />
              </div>

              <div className="flex items-center justify-center">
                <div className="grid grid-cols-4 p-5 gap-10 w-full bg-gradient-to-tr from-white to-gray-300">
                  {produtos.map((produto) => (
                    <InvestmentCard
                      key={produto.id}
                      status={produto.status}
                      name={produto.type}
                      idproduto={produto.id}
                      price={`${
                        formatToBRL(produto.price) || "Não disponível"
                      }`} // Formata o preço
                      dailyProfit={`${
                        formatToBRL(produto.dailyProfit) || "Não disponível"
                      }`} // Formata o lucro diário
                      totalProfit={`${
                        formatToBRL(produto.totalProfit) || "Não disponível"
                      }`} // Formata o lucro total
                      days={parseInt(produto.returnPeriod)} // Extraí os dias do período de retorno
                      onInvest={(status) => {
                        if (status === "Investimento feito com sucesso!") {
                          notifySuccess(status); // Chama notifySuccess se o investimento for bem-sucedido
                        } else {
                          notifyError(status); // Chama notifyError se houver um erro
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <ToastContainer />
      <div className="block md:hidden">
        <MobileView />
      </div>
      <div className="hidden md:block">
        <DesktopView />
      </div>
    </div>
  );
};

export default Index;
