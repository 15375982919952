export default function Example() {
  return (
    <div className="relative w-full isolate rounded-lg overflow-hidden bg-gray-900 py-24 sm:py-32">
      <img
        alt=""
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-[50px] font-semibold tracking-tight text-white">
            Afiliados
          </h2>

          <p className="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
            Ao compartilhar seu link de convite, você receberá até 15% em
            dinheiro de volta do valor do investimento de seus amigos.
          </p>
          {/* Bloco de informações sobre o Prêmio Convite */}
          <div className="mt-8 text-gray-200 space-y-4 text-lg text-justify">
            <p>Prêmio Convite:</p>
            <p>
              1. Quando seus amigos convidados se inscreverem e concluírem o
              investimento, você receberá instantaneamente 15% em dinheiro de
              volta no nível 1.
            </p>
            <p>
              2. Quando os membros da sua equipe de nível 2 investirem, você
              ganhará 1% em dinheiro de volta.
            </p>
            <p>
              3. Quando os membros da sua equipe de nível 3 investirem, você
              ganhará 1% em dinheiro de volta.
            </p>
            <p>
              4. Após o investimento, as recompensas em dinheiro são enviadas
              instantaneamente para o saldo disponivel da sua conta.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
