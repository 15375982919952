import React, { useState } from "react";

// Importando todas as imagens necessárias
import robo01 from "../../assets/robos/01-1.png";

import robo02 from "../../assets/robos/02.png";

import robo03 from "../../assets/robos/03.png";

import robo04 from "../../assets/robos/04.png";

import robo05 from "../../assets/robos/05.png";

import robo08 from "../../assets/robos/08.png";

import robo09 from "../../assets/robos/09.png";

import robo10 from "../../assets/robos/10.png";

import { fetchInvestir } from "../../integration/investir/fetchInvestir";

const InvestmentCard = ({
  idproduto,
  status,
  name,
  price,
  dailyProfit,
  totalProfit,
  days,
  onInvest,
}) => {
  const [loading, setLoading] = useState(false);
  let img01 = null;
  let img01_bg = null;

  // Mapeando os nomes dos robôs para as respectivas imagens
  const robotImages = {
    "Edu Robot": { img: robo02, img_bg: robo02 },
    "Envi Robot": { img: robo04, img_bg: robo04 },
    "Agr Robot": { img: robo03, img_bg: robo03 },
    "Med Robot": { img: robo01, img_bg: robo01 },
    "War Robot": { img: robo10, img_bg: robo10 }, // Assumindo que War Robot não precisa de bg
    "Sex Robot": { img: robo09, img_bg: robo09 }, // Assumindo que Sex Robot não precisa de bg
    "GPT Advanced": { img: robo05, img_bg: robo05 },
    "GPT Ultra": { img: robo08, img_bg: robo08 },
  };

  const selectedRobot = robotImages[name];

  if (selectedRobot) {
    img01 = selectedRobot.img;
    img01_bg = selectedRobot.img_bg;
  }

  // Função para buscar o saldo
  async function funcInvestir() {
    setLoading(true);

    try {
      const resp = await fetchInvestir(idproduto);
      // Simulando uma chamada assíncrona (substitua isso pelo seu código real)
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Espera 2 segundos

      onInvest(resp);

      setLoading(false);
    } catch (erro) {
      onInvest(erro);

      // Simulando uma chamada assíncrona (substitua isso pelo seu código real)
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Espera 2 segundos
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`relative mb-10 w-full rounded-3xl shadow-lg overflow-hidden transition-transform transform duration-300 ${
          status === "pausado" ? "bg-gray-400 opacity-50" : "bg-[#486e7d]"
        }`}
      >
        {/* Imagem de fundo com opacidade */}
        <div
          className="absolute inset-0 bg-cover bg-center opacity-95"
          style={{ backgroundImage: `url(${img01_bg})` }} // Corrigido para usar img01_bg
        ></div>

        {/* Tela de carregamento */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
            <p className="text-white text-lg">Verificando saldo, aguarde...</p>
          </div>
        )}

        {/* Conteúdo principal sobre o background */}

        {!loading && (
          <>
            <div
              className={`relative z-10 p-4 flex flex-col items-center text-center space-y-4 bg-gray-900 bg-opacity-80 rounded-3xl ${
                loading ? "opacity-50" : "opacity-100"
              }`}
            >
              {/* Imagem principal maior no topo */}
              <div className="w-full h-40 overflow-hidden shadow-xl transform transition-transform duration-300 rounded-lg">
                <img
                  src={img01} // Usando img01 para a imagem principal
                  alt={`Foto de ${name}`}
                  className="w-full h-full object-cover"
                />
              </div>

              <table className="min-w-full bg-gray-800 text-[15px] whitespace-nowrap rounded-lg shadow-lg">
                <thead>
                  <tr className="bg-gray-700 text-gray-200 text-center">
                    <th className="py-2 px-4 font-semibold">Informação</th>
                    <th className="py-2 px-4 font-semibold">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-gray-600 hover:bg-gray-700 transition duration-300">
                    <td className="text-gray-400  py-2 px-4 font-semibold">
                      Tipo:
                    </td>
                    <td className="text-gray-400 py-2 px-4">{name}</td>
                  </tr>
                  <tr className="border-b border-gray-600 hover:bg-gray-700 transition duration-300">
                    <td className="text-gray-400 py-2 px-4 font-semibold">
                      Preço:
                    </td>
                    <td className="text-gray-400 py-2 px-4">{price}</td>
                  </tr>
                  <tr className="border-b border-gray-600 hover:bg-gray-700 transition duration-300">
                    <td className="py-2 px-4 font-semibold text-green-400">
                      Lucro diário:
                    </td>
                    <td className="py-2 px-4">{dailyProfit}</td>
                  </tr>
                  <tr className="border-b border-gray-600 hover:bg-gray-700 transition duration-300">
                    <td className="text-gray-400 py-2 px-4 font-semibold">
                      Lucro total:
                    </td>
                    <td className="text-gray-400 py-2 px-4">{totalProfit}</td>
                  </tr>
                  <tr className="border-b border-gray-600 hover:bg-gray-700 transition duration-300">
                    <td className="text-gray-400 py-2 px-4 font-semibold">
                      Retorno:
                    </td>
                    <td className="text-gray-400 py-2 px-4">{days} dias</td>
                  </tr>
                </tbody>
              </table>
              <button
                onClick={funcInvestir}
                disabled={status === "pausado"}
                className={`mt-4 px-6 py-2 font-semibold rounded-full shadow-lg transform transition-transform duration-300 ${
                  status === "pausado"
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4] text-[#2e3543] hover:scale-105"
                }`}
              >
                Investir
              </button>
            </div>
          </>
        )}

        {/* Efeito de borda luminosa */}
        <div className="absolute -inset-1 rounded-3xl border border-blue-600 opacity-30 animate-pulse" />
      </div>
    </>
  );
};

export default InvestmentCard;
