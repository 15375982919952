import { useState, createContext } from "react";
import { api } from "../services/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AuthContext = createContext({});

export function AuthContextProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function signAdmin(user) {
    setLoading(true);
    setError("");

    await api
      .post("/login", user)
      .then(({ data }) => {
        const token = data.token;
        try {
          // Token é válido, salva no localStorage
          localStorage.setItem("@wornnex:token", token);

          // Redireciona para o dashboard
          navigate("/dashboard");
        } catch (error) {
          // Erro ao decodificar o token
          setError("Erro ao validar o token. Faça login novamente.");
          toast.error("Erro ao validar o token. Faça login novamente.");
        }
      })
      .catch(({ response }) => {
        setError(response.data);
        toast.error(`Erro: ${response.data}`);
      })
      .finally(() => setLoading(false));
  }

  function logout() {
    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ user, loading, error, signAdmin, logout }}>
      {props.children}
      {/* Container para exibir os toasts */}
      <ToastContainer />
    </AuthContext.Provider>
  );
}
