import React, { useState, useEffect } from "react";
import { formatToBRL } from "brazilian-values";

import { fetchSaldo } from "../../integration/saldo/fetchSaldo";

const Cartao = ({ refresh }) => {
  const [balance, setBalance] = useState(0);
  const [balance2, setBalance2] = useState(0);

  if (refresh === true) {
    functionBalance();
    refresh = false;
  }

  useEffect(() => {
    functionBalance();
  }, []);

  async function functionBalance() {
    try {
      const resp = await fetchSaldo();
      setBalance(resp.balance);

      setBalance2(resp.balance2);
    } catch (erro) {
      setBalance([]);
    }
  }

  return (
    <div className="w-96 h-56 bg-[#2e3543] rounded-xl  border border-slate-400 relative text-white shadow-2xl transition-transform transform hover:scale-110">
      <div className="w-full px-8 absolute top-6">
        <div className="text-[17px] flex justify-between">
          <div>
            <p className="text-[15px] font-light">Saldo Disponível</p>
            <p className="text-green-400 text-[15px]  font-extrabold tracking-more-wider">
              {formatToBRL(balance)}
            </p>
          </div>

          <span className="text-white text-[20px] font-extrabold opacity-60">
            wornnex
          </span>
        </div>
        <div className=" text-slate-400 pt-1 text-[13px] mt-5">
          <p className="font-light">Disponível para Saque</p>
          {/* Bolinha verde piscando */}
          <div className="flex items-center mt-1">
            {/* Bolinha verde piscando */}
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse mr-2"></div>
            {/* Valor disponível para saque */}
            <p className="font-medium tracking-more-wider">
              {formatToBRL(balance2)}
            </p>
          </div>
        </div>
        <div className="pt-6 pr-6 text-[15px]">
          <div className="flex justify-between">
            <div>
              <p className="font-light text-xs">Rendimentos</p>
              <p className="font-medium tracking-wider text-[14px]">Diário</p>
            </div>
            <div>
              <p className="font-light text-xs">Nivel</p>
              <p className="font-medium tracking-wider text-[14px]">Start</p>
            </div>
            <div>
              <p className="font-light text-xs">CVV</p>
              <p className="font-bold tracking-more-wider text-sm">···</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cartao;
