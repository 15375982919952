import React, { useState } from "react";
import { useAuth } from "../../../hooks/Auth";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BiLockAlt } from "react-icons/bi";
import logo from "./../../../assets/logo/logo.png";
import { SiAbbrobotstudio } from "react-icons/si";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { GiArchiveRegister } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const nomedosistema = "WORNNEX";
  const { signAdmin, error } = useAuth();
  const [user, setUser] = useState({
    phone: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleWithSubmit = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    if (user.telefone === "" || user.senha === "") {
      return;
    }

    await signAdmin(user);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-tr from-[#ffffff] to-[#DCDCDC] bg-cover bg-center relative">
      <div className="justify-center items-center self-center z-10 p-5 rounded-md">
        <form onSubmit={handleWithSubmit} className="w-full max-w-md">
          <h1 className="flex items-center justify-center mb-8">
            <img className="h-24" src={logo} alt="" />
          </h1>

          <div className="flex items-center bg-[#d7d7d7] border-2 none border-[#c7c7c7] text-[#516f7b] py-2 px-3 rounded-2xl mb-4">
            <MdOutlinePhoneAndroid className="h-5 w-5 text-[#516f7b]" />
            +55
            <input
              className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-[#2d4853]"
              onChange={handleChange}
              value={user.phone}
              type="tel"
              name="phone"
              placeholder="Insira o número da conta"
            />
          </div>

          <div className="flex items-center bg-[#d7d7d7] border-2 none border-[#c7c7c7] py-2 px-3 rounded-2xl mb-4">
            <BiLockAlt className="h-5 w-5 text-[#2d4853]" />
            <input
              className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-[#2d4853]"
              placeholder="Senha"
              onChange={handleChange}
              value={user.password}
              type={showPassword ? "text" : "password"}
              name="password"
            />
            {showPassword ? (
              <RiEyeOffFill
                className="h-5 w-5 text-[#2d4853] ml-2 cursor-pointer"
                onClick={handleTogglePassword}
              />
            ) : (
              <RiEyeFill
                className="h-5 w-5 text-[#2d4853] ml-2 cursor-pointer"
                onClick={handleTogglePassword}
              />
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#2d4853] to-[#375d69] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
          >
            <SiAbbrobotstudio className="mr-2 text-slate-100 font-bold" />{" "}
            Acessar
          </button>
        </form>
        <button
          onClick={() => navigate("/register/")}
          className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#d7d7d7] to-[#d7d7d7] mt-4 py-2 rounded-2xl text-[#2d4853] mb-2 flex items-center justify-center"
        >
          <GiArchiveRegister className="mr-2 text-[#2d4853] font-bold" />{" "}
          Cadastrar
        </button>

        <h1 className="text-[#656565] text-center text-[10px] mt-4">
          Copyright © 2022 - 2024 {nomedosistema}
        </h1>
        <h1 className="text-[#656565] text-center text-[10px]">
          Todos os direitos reservados
        </h1>
      </div>
    </div>
  );
}

export default Login;
