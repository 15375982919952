import React, { useState, useEffect } from "react";
import CardInvit from "../../components/card_invity2";
import { fetchAfiliadoQtd } from "../../integration/afiliados_qtd/fetchAfiliadoQtd";
import { fetchAfiliadoHist } from "../../integration/afiliados_hist/fetchAfiliadoHist";
import { formatToBRL } from "brazilian-values";

const CardWithTabs = () => {
  const [activeTab, setActiveTab] = useState("nivel1");
  const [dataa, setData] = useState([]);
  const [dataa2, setData2] = useState({
    nivel1: [],
    nivel2: [],
    nivel3: [],
  });

  // Função para exibir apenas os 2 primeiros e os 2 últimos dígitos do telefone
  const maskTelefone = (telefone) => {
    const digits = telefone.replace(/\D/g, ""); // Remove caracteres não numéricos
    return digits.length > 4
      ? `${digits.slice(0, 3)}*****${digits.slice(-4)}`
      : telefone; // Retorna o telefone original se tiver menos de 4 dígitos
  };

  useEffect(() => {
    functQtd();
    functHist();
  }, []);

  const functQtd = async () => {
    try {
      const resp = await fetchAfiliadoQtd();
      setData(resp);
    } catch (erro) {
      setData([]);
    }
  };

  const functHist = async () => {
    try {
      const resp = await fetchAfiliadoHist();

      const nivelData = {
        nivel1: resp.filter((item) => item.nivel === "n1"),
        nivel2: resp.filter((item) => item.nivel === "n2"),
        nivel3: resp.filter((item) => item.nivel === "n3"),
      };

      setData2(nivelData);
    } catch (erro) {
      setData2({
        nivel1: [],
        nivel2: [],
        nivel3: [],
      });
    }
  };

  return (
    <div className="overflow-x-auto w-full bg-gray-800 rounded-xl shadow-lg">
      <div className="flex justify-center p-4 mb-4 bg-gray-700 rounded-lg shadow-md">
        <div className="text-center">
          <h2 className="text-gray-200 text-lg font-semibold">
            Link de Convite:
          </h2>
          <p className="text-gray-300 font-bold">
            <CardInvit />
          </p>
        </div>
      </div>

      <div className="flex justify-center p-4 mb-4 bg-gray-700 rounded-lg shadow-md">
        <div className="text-center">
          <h2 className="text-gray-200 text-lg font-semibold">
            Convites Direto:
          </h2>
          <p className="text-gray-300 text-2xl font-bold">
            {dataa.qtd_invity || 0}
          </p>
        </div>
      </div>

      <div className="flex bg-gray-700">
        {["nivel1", "nivel2", "nivel3"].map((nivel) => (
          <button
            key={nivel}
            onClick={() => setActiveTab(nivel)}
            className={`w-full py-2 text-gray-200 uppercase font-semibold ${
              activeTab === nivel ? "bg-gray-600 text-white" : "bg-gray-500"
            }`}
          >
            {nivel === "nivel1"
              ? "Nível 1"
              : nivel === "nivel2"
              ? "Nível 2"
              : "Nível 3"}
          </button>
        ))}
      </div>

      <div className="p-4">
        {dataa2[activeTab] && dataa2[activeTab].length > 0 ? (
          <table className="min-w-full bg-gray-800 rounded-xl">
            <thead className="bg-gray-700 text-gray-200 uppercase text-sm">
              <tr>
                <th className="py-3 px-6">Telefone</th>
                <th className="py-3 px-6">Valor Investido</th>
              </tr>
            </thead>
            <tbody className="text-gray-300 text-sm">
              {dataa2[activeTab].map((item, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-700 transition-colors duration-200 text-center"
                >
                  <td className="py-3 px-6">{maskTelefone(item.phone)}</td>
                  <td className="py-3 px-6">{formatToBRL(item.invist)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-[18px] text-slate-700 flex items-center justify-center h-full">
            <p>Sem Investimentos</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardWithTabs;
