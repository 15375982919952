import { api } from "../../services/api";

export async function fetchPayment(selectedAmount) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@wornnex:token");

    api
      .post(
        "/generate-payment",
        {
          amount: selectedAmount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
