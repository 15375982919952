import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { fetchAlterarSenha } from "../../integration/alterarsenha/fetchAlterarSenha";

const MeuPerfil = () => {
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");

  const [novasenha, setNovasenha] = useState("");
  const [repetirsenha, setRepetirsenha] = useState("");

  const handleValorChange = (e) => {
    setNovasenha(e.target.value);
  };

  const handleWalletChange = (e) => {
    setRepetirsenha(e.target.value);
  };

  async function funcAlterarSenha() {
    try {
      const resp = await fetchAlterarSenha(novasenha, repetirsenha);
      setNovasenha("");
      setRepetirsenha("");
      toast.success(resp);
    } catch (erro) {
      toast.error(erro);
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="overflow-x-auto w-full max-w-md mx-auto bg-gray-800 rounded-xl shadow-lg p-10">
        {!sucess && (
          <div>
            <h2 className=" text-slate-200 text-[15px] mb-5 font-bold uppercase">
              Alterar Senha
            </h2>

            <div className="mb-4 text-[15px]">
              <label htmlFor="valorInvestido" className="block mb-2">
                Nova Senha:
              </label>
              <input
                type="text"
                className="border p-2 rounded-md w-full text-slate-800"
                value={novasenha}
                onChange={handleValorChange}
                placeholder="Nova Senha"
              />
              <label htmlFor="valorInvestido" className="block mt-2 mb-2">
                Repetir Senha:
              </label>
              <input
                type="text"
                className="border p-2 rounded-md w-full text-slate-800"
                value={repetirsenha}
                onChange={handleWalletChange}
                placeholder="Repetir Senha"
              />
            </div>

            <button
              onClick={funcAlterarSenha}
              className="flex items-center px-4 py-2 bg-[#486e7d] text-white text-sm font-semibold rounded-md hover:bg-[#2d4853] transition-colors"
            >
              Alterar Senha
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MeuPerfil;
