import React, { useState } from "react";
import {
  HomeIcon,
  CashIcon,
  ChartBarIcon,
  UserIcon,
  SupportIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import Logo from "../../assets/logo/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sidebar = () => {
  // Estado para controlar a visibilidade do menu
  const [isOpen, setIsOpen] = useState(false);

  // Função para alternar a visibilidade do menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClickDisconect = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleWithdrawClick = () => {
    // Exibe o toast com a mensagem
    toast.warn(
      "A conta ainda não atingiu o período de qualificação de 24 horas após o início do investimento."
    );

    // Redirecionamento comentado para evitar navegação imediata
    // window.location.href = "/withdraw";
  };

  return (
    <>
      {/* Navbar superior */}
      <nav className="bg-gradient-to-tl from-white to-gray-300 border-b border-gray-300 dark:bg-gray-900">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <a href="https://wornnex.com" className="flex items-center space-x-3">
            <img src={Logo} className="h-16" alt="WORNNEX" />
          </a>

          <div className="flex items-center space-x-6">
            {/* Botão de Suporte */}
            <a
              href="https://t.me/WORNNEXAPP"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-4 py-2 bg-[#486e7d] text-white text-sm font-semibold rounded-md hover:bg-[#2d4853] transition-colors"
            >
              <SupportIcon className="h-5 w-5 mr-2" />
              Suporte
            </a>

            <a
              onClick={(e) => {
                e.preventDefault(); // Impede o comportamento padrão
                handleMenuClickDisconect(); // Chama a função ao clicar
              }}
              className="text-sm font-bold text-[#2d4853] hover:underline"
            >
              Sair
            </a>

            {/* Botão para abrir o menu no mobile */}
            <button
              className="md:hidden flex items-center p-2 text-gray-500 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
              onClick={toggleMenu}
            >
              {/* Ícone do menu (Hamburger) */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Sidebar com ícones */}
      <nav
        className={`bg-[#2e3543]   dark:bg-gray-700 ${
          isOpen ? "block" : "hidden"
        } md:block`}
      >
        <div className="max-w-screen-xl px-4 py-3  mx-auto shadow-lg">
          <div className="flex items-center justify-center">
            <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 text-sm text-gray-200 dark:text-white">
              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={() => (window.location.href = "/")}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <HomeIcon className="h-5 w-5" />
                  <span>Home</span>
                </button>
              </li>

              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={() => (window.location.href = "/deposit")}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <CashIcon className="h-5 w-5" />
                  <span>Deposito</span>
                </button>
              </li>

              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={handleWithdrawClick}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <CashIcon className="h-5 w-5" />
                  <span>Sacar</span>
                </button>
              </li>

              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={() => (window.location.href = "/activity")}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <ChartBarIcon className="h-5 w-5" />
                  <span>Atividade</span>
                </button>
              </li>

              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={() => (window.location.href = "/group")}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <UserGroupIcon className="h-5 w-5" />
                  <span>Equipe</span>
                </button>
              </li>

              <li className="flex items-center space-x-2 hover:text-white transition-colors">
                <button
                  onClick={() => (window.location.href = "/perfil")}
                  className="flex items-center space-x-2 hover:underline focus:outline-none"
                >
                  <UserIcon className="h-5 w-5" />
                  <span>Perfil</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
