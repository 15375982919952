import { Navigate, Outlet } from "react-router-dom";
import jwt from "jwt-decode";

export default function ProtectedRoute({ redirectPath = "/" }) {
  const token = localStorage.getItem("@wornnex:token");

  let isValid = false;

  if (token) {
    const unscrypted = jwt(token);
    isValid = !!unscrypted.iat && !!unscrypted.usr_id;

    let linkaff = unscrypted.usr_codigo;
    localStorage.setItem("@wornnex:affiliateLink", linkaff);
  }

  if (!token || !isValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
